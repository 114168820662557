@import './variables';
html,
body,
#root {
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $fd-theme-background;
    color: #eee;
    padding: $fd-theme-padding;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
    box-sizing: border-box;
}

button {
    >svg {
        vertical-align: middle;
    }
}