@import '../variables';
$circle-size: 126px;
$circle-padding: 20px;
.fd-rating {
    display: grid;
    gap: 1%;
    grid-template-columns: 49.5% 49.5%;
    position: absolute;
    top: $fd-theme-padding;
    bottom: $fd-theme-padding;
    left: $fd-theme-padding;
    right: $fd-theme-padding;
    &-item {
        @include fd-mix-container;
        display: grid;
        gap: 2%;
        grid-template-columns: 15% 15% 15% 15% 15% 15%;
        grid-template-rows: 116px auto;
        border-radius: $fd-theme-padding;
        .fd-rating-title {
            font-size: 1.1em;
        }
        .fd-rating-bar {
            // height: 8px;
            // background-color: #eee;
            font-size: 2.6em;
        }
        .fd-rating-avatar {
            background-position: center;
            background-size: cover;
            border-radius: $fd-theme-padding;
            // margin-left: auto;
            width: 100px;
            height: 100px;
            border: 2px solid #cecece;
            box-shadow: 0 0 5px black;
        }
    }
    &-cover {
        @include fd-mix-padding;
        background-size: contain;
        border-radius: $fd-theme-padding;
        grid-column: span 2;
    }
    &-money {
        @include fd-mix-padding;
        border-radius: $fd-theme-padding;
        padding-top: 40px;
        padding-right: 40px;
        background-color: #cdfcbe;
        box-shadow: inset -115px 0 0 -100px #38c70052, inset 0 115px 0 -100px #dfffbb, inset 0 135px 0 -100px;
        direction: ltr;
        text-align: right;
        grid-column: span 2;
        &-amount {
            font-size: 50px;
        }
    }
    &-points {
        @include fd-mix-padding;
        border-radius: $fd-theme-padding;
        background-color: #ffeabb;
        box-shadow: inset -115px 0 0 -100px #fb8f006e, inset 0 115px 0 -100px #ffeabb, inset 0 135px 0 -100px;
        grid-column: span 2;
        direction: ltr;
        text-align: right;
        padding-top: 40px;
        padding-right: 40px;
        font-size: 50px;
        svg {
            vertical-align: -5px;
        }
    }
    &-countdowns {
        display: flex;
        flex-wrap: wrap;
        grid-column: span 6;
        grid-row: span 1;
        .circular {
            height: $circle-size;
            width: $circle-size;
            position: relative;
            .inner {
                position: absolute;
                z-index: 6;
                top: 50%;
                left: 50%;
                height: $circle-size - $circle-padding;
                width: $circle-size - $circle-padding;
                margin: (-($circle-size - $circle-padding)/2) 0 0 (-($circle-size - $circle-padding)/2);
                border-radius: 100%;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 70%;
                background-color: blanchedalmond;
                overflow: hidden;
            }
            .number {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 10;
                font-size: 18px;
                font-weight: 500;
            }
            .circle {
                .bar {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    // background: #fff;
                    border-radius: 100%;
                    clip: rect(0px, $circle-size, $circle-size, $circle-size/2);
                    .progress {
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        border-radius: 100%;
                        clip: rect(0px, $circle-size/2, $circle-size, 0px);
                        background: currentColor;
                    }
                }
                .left {
                    .progress {
                        transform: rotate(180deg);
                        z-index: 1;
                    }
                }
                .right {
                    transform: rotate(180deg);
                    z-index: 3;
                    .progress {
                        transform: rotate(70deg);
                    }
                }
            }
        }
    }
    &-countdown {
        flex-shrink: 0;
        margin: $fd-theme-padding;
        border-radius: 50%;
        // border: 3px solid;
        &-100 {
            box-shadow: 0 0 20px;
            .inner {
                box-shadow: inset 0 0 20px;
            }
        }
        &-forbidden:not(&-100),
        &-allowed#{&-100} {
            color: red;
            .inner {
                // box-shadow: inset 0 0 0 1px;
                &::before {
                    content: '';
                    width: 100%;
                    height: 10%;
                    position: absolute;
                    background: red;
                    transform: translate(0%, 450%) rotate(135deg);
                }
            }
        }
        &-allowed:not(&-100),
        &-forbidden#{&-100} {
            color: green;
            .inner {
                &::before {
                    content: '';
                }
            }
        }
        &-forbidden#{&-100},
        &-allowed#{&-100} {
            .left {
                .progress {
                    transform: rotate(180deg) !important;
                }
            }
            .right {
                .progress {
                    transform: rotate(180deg) !important;
                }
            }
        }
        .number {
            text-shadow: 0px 0px 3px rgb(73, 73, 73);
            * {
                background-color: rgba(255, 255, 255, 0.479);
            }
        }
        &-inner {
            &-date {}
            &-money {
                font-size: 30px;
            }
            &-stars {
                font-size: 30px;
            }
        }
    }
}