@import '../variables';

$border-radius: $fd-theme-padding;

.fd-birthdays {
    display: flex;
    flex-direction: column;

    .fd-birthdays-item {
        overflow: hidden;
        border-radius: $border-radius;
        background-color: green;
        display: flex;
        flex-grow: 1;

        .fd-birthdays-bar-container {
            flex-grow: 1;
            background-color: rgba(128, 128, 128, 0.8);
            border-radius: $border-radius;
        }
        .fd-birthdays-age {
            display: none;
            // width: $rowSize;
            // height: $rowSize;
            line-height: $border-radius;
            // font-size: $rowSize * 0.6;
            text-align: center;
            &-from {}
            &-to {}
        }
        .fd-birthdays-avatar {
            width: fit-content;
            height: 100%;
            background-position: center;
            background-size: cover;
            border-radius: $border-radius;
            margin-left: auto;

            // &:after {
            //     content: "";
            //     display: block;
            //     padding-bottom: 100%;
            // }
        }
        // .fd-birthdays-title {}
        .fd-birthdays-bar {
            background-color: rgba(238, 238, 238, 0.5);
            background-size: contain;
            background-repeat: repeat;
            background-position: left center;
            border-radius: $border-radius;
            overflow: hidden;
            height: 100%;
            min-width: 100px;
        }
        &+.fd-birthdays-item {
            margin-top: $fd-theme-padding;
        }
    }
}