$fd-theme-color: #eee;
$fd-theme-padding: 8px;
$fd-theme-background: rgb(53, 53, 53);
@mixin fd-mix-padding {
    padding: $fd-theme-padding;
}

@mixin fd-mix-border {
    border: 1px solid $fd-theme-color;
}

@mixin fd-mix-color {
    color: $fd-theme-color;
}

@mixin fd-mix-container {
    @include fd-mix-border;
    @include fd-mix-color;
    @include fd-mix-padding;
}