@import '../variables.scss';
.fd-admin {
    @include fd-mix-container();
    overflow-y: scroll;
    &-children {
        @include fd-mix-container();
    }
    &-child {
        @include fd-mix-container();
        &-money {}
        &-actions {
            span {
                margin: 2px;
            }
        }
    }
    small {
        font-size: 0.5em;
    }
}