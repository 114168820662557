@import "../variables.scss";
.fd-calendar {
    display: flex;
    .fd-bulb {
        display: inline-block;
        border-radius: 50%;
        background-color: rgb(199, 199, 199);
        width: 1.25em;
        height: 1.25em;
        border: 2px solid #aaa;
        vertical-align: sub;
        &-on {
            background-color: green;
        }
    }
    .fd-stack {
        .fd-item {
            line-height: 24px;
            border: 2px solid #aaa;
            margin-bottom: -2px;
            padding: 8px;
        }
    }
    .fd-clock-timepoints {
        @include fd-mix-padding;
        flex-grow: 2;
        flex-shrink: 0;
        .fd-clock-digital {
            font-family: monospace;
            .fd-clock-hours {
                font-size: 65px;
            }
            .fd-clock-minutes {
                font-size: 35px;
                vertical-align: top;
            }
        }
        .fd-clock-timepoint-name {
            font-size: 55px;
        }
    }
    .fd-weekdays {
        .fd-weekdays-day {
            background-color: rgb(110, 110, 110);
            &.fd-weekdays-weekend {
                background-color: rgb(173, 0, 0);
            }
        }
    }
    .fd-seasons {
        .fd-season {}
    }
    .fd-season {
        &-0 {
            background-color: rgb(0, 105, 167);
        }
        &-1 {
            background-color: rgb(190, 0, 174);
        }
        &-2 {
            background-color: rgb(158, 148, 0);
        }
        &-3 {
            background-color: rgb(190, 76, 0);
        }
    }
    .fd-clock {
        width: 80vh;
        height: 80vh;
        border: 1px solid red;
        /* Clock styles */
        .circle {
            fill: none;
            stroke: #000;
            stroke-width: 9;
            stroke-miterlimit: 10;
        }
        &-face {
            .mid-circle {
                fill: #000;
            }
        }
        .hour-marks {
            fill: none;
            stroke: #000;
            stroke-width: 9;
            stroke-miterlimit: 10;
        }
        .hour-arm {
            fill: none;
            stroke: #000;
            stroke-width: 17;
            stroke-miterlimit: 10;
        }
        .minute-arm {
            fill: none;
            stroke: #000;
            stroke-width: 11;
            stroke-miterlimit: 10;
        }
        .second-arm {
            fill: none;
            stroke: rgb(145, 0, 0);
            stroke-width: 4;
            stroke-miterlimit: 10;
            .mid-circle {
                fill: #000;
            }
        }
        /* Transparent box ensuring arms center properly. */
        .sizing-box {
            fill: none;
        }
        &-second {
            .mid-circle {
                fill: rgb(145, 0, 0);
            }
        }
        /* Make all arms rotate around the same center point. */
        /* Optional: Use transition for animation. */
        &-hour,
        &-minute,
        &-second {
            transform-origin: 300px 300px;
            transition: transform 2s linear;
        }
    }
}