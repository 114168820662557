@import './variables';
.fd-app {
    // @include fd-mix-padding;
    // opacity: 0.5;
    // margin: -1px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .fd-app-header {
        padding: $fd-theme-padding;
        .fd-app-nav {
            display: flex;
            &-item {
                color: $fd-theme-color;
                background-color: $fd-theme-background;
                flex-grow: 1;
                margin: $fd-theme-padding;
                border: 1px solid $fd-theme-color;
                &-active {
                    background-color: $fd-theme-color;
                    color: $fd-theme-background;
                    box-shadow: inset 0 0 0 1px $fd-theme-background;
                }
                &-action {
                    flex-grow: 0;
                }
            }
        }
    }
    .fd-app-sections {
        padding: $fd-theme-padding;
        overflow: hidden;
        flex-grow: 1;
        position: relative;
    }
    .fd-app-footer {
        padding: $fd-theme-padding;
    }
}

.fd-page {
    // height: 100%; // Not working on the Android Dungle
    position: absolute;
    top: $fd-theme-padding;
    bottom: $fd-theme-padding;
    left: $fd-theme-padding;
    right: $fd-theme-padding;
}
